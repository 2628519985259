import { ColumnsType } from 'antd/es/table'
import { CARD_EXAMPLE, FormatCard } from './const'

export function isJestTest() {
  return process.env.JEST_WORKER_ID !== undefined
}

export const getServerGoAPI = (path: string) => `${process.env.NEXT_PUBLIC_GO_API_URL}/${path}`
// export const getServerGoAPI = (path: string) => `${API_GO_ROOT_REMOTE}/${path}`

export const isBrowser = () => typeof window !== 'undefined'
export const isServer = () => typeof window === 'undefined'

export enum VIP_LEVEL {
  'NORMAL' = 10,
  'VIP' = 20,
  'SVIP' = 30,
  'SSVIP' = 40,
  'SUPER_ADMIN' = 100,
  'normal' = 10,
  'vip' = 20,
  'svip' = 30,
  'ssvip' = 40,
  'super_admin' = 100,
}

export const teamColumns: ColumnsType = [
  { title: '排名', align: 'center', dataIndex: 'rank', render: (v) => <div className="font-bold">{v}</div> },
  { title: '队名', align: 'center', dataIndex: 'name' },
  { title: '队员1', align: 'center', dataIndex: 'name_user1' },
  { title: '队员2', align: 'center', dataIndex: 'name_user2' },
  { title: '团体', align: 'center', dataIndex: 'guild' },
]

export const spinColumns: ColumnsType = [
  { title: '排名', align: 'center', dataIndex: 'rank', render: (v) => <div className="font-bold">{v}</div> },
  { title: '姓名', align: 'center', dataIndex: 'name' },
]

export const rankToNumber: Record<string, number> = {
  弃权: -10,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  J: 11,
  Q: 12,
  K: 13,
  A: 14,
  'A+': 15,
  'A＋': 15,
  j: 11,
  q: 12,
  k: 13,
  a: 14,
  'a+': 15,
  'a＋': 15,
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '15': 15,
  L: 16,
  B: 17,
}

export const excessRankToNumber: Record<string, number> = {
  弃权: -10,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  J: 11,
  Q: 12,
  K: 13,
  A: 14,
  j: 11,
  q: 12,
  k: 13,
  a: 14,
  '2+': 15,
  '3+': 16,
  '4+': 17,
  '5+': 18,
  '6+': 19,
  '7+': 20,
  '8+': 21,
  '9+': 22,
  '10+': 23,
  'J+': 24,
  'Q+': 25,
  'K+': 26,
  'A+': 27,
  'j+': 24,
  'q+': 25,
  'k+': 26,
  'a+': 27,
}

export const numberToRank = {
  '-10': '弃',
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 'J',
  12: 'Q',
  13: 'K',
  14: 'A',
  15: 'A+',
}

export const excessNumberToRank = {
  '-10': '弃',
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 'J',
  12: 'Q',
  13: 'K',
  14: 'A',
  15: '2+',
  16: '3+',
  17: '4+',
  18: '5+',
  19: '6+',
  20: '7+',
  21: '8+',
  22: '9+',
  23: '10+',
  24: 'J+',
  25: 'Q+',
  26: 'K+',
  27: 'A+',
}

export const roomStatus = {
  10: '空闲',
  20: '等待中',
  30: '比赛中',
  32: '单提交',
  35: '已提交',
  40: '已结束',
  null: '空闲',
}

export enum RoomStatusNum {
  created = 10,
  waiting = 20,
  ongoing = 30,
  halfsubmitted = 32,
  submitted = 35,
  finished = 40,
}

export enum GameStatus {
  stopped = 0,
  started = 1,
  preparing = 2,
}

export const roomColor = {
  10: 'text-gray-500',
  20: 'text-green-500',
  30: 'text-blue-500',
  32: 'text-blue-600',
  35: 'text-yellow-500',
  40: 'text-red-500',
  null: 'text-gray-500',
}

export const roomBg = {
  10: 'bg-white',
  20: 'bg-white',
  30: 'bg-white',
  35: 'bg-yellow-100',
  40: 'bg-gray-200',
  null: 'bg-white',
}

export const normalScoresMap = {
  victory_score: '场分',
  victory_count: '胜轮次',
  score: '级差分',
  rank_up_score: '升级数',
  opponent_score: '对手级差分',
  opponent_victory_score: '对手场分',
  score_log: '轮高分',
}

export const duplicatedMatchScoresMap = {
  score: '复式积分',
  five_score_count: '5分次数',
  four_score_count: '4分次数',
  three_score_count: '3分次数',
  opponent_score: '对手分',
}

export const duplicatedTeamMatchScoresMap = {
  ...normalScoresMap,
  victory_score: '复式场分',
  opponent_score: '对手积分',
  score: '复式积分',
}

export const duplicatedSwitzerMatchScoresMap = {
  offset_score: '科学偏差分',
  offset_victory_score: '科学场分',
  offset_median_score: '中位数偏差分',
  offset_median_victory_score: '中位数场分',
  offset_median_victory_score_5_stages: '中位5段场分',
  percent_score: '百分位场分',
  opponent_score: '对手积分',
  opponent_median_victory_score: '对手中位场分',
}

export const duplicatedSpinMatchScoresMap = {
  offset_median_victory_score: '中位数场分',
  offset_median_score: '中位数偏差分',
  opponent_offset_median_victory_score: '对手场分',
}

export const singlePublicMatchScoresMap = {
  single_public_score: '个人赛积分',
  total_ranking: '名次总计',
  rank_up_score: '升级分',
}

export const spinMatchScoresMap = {
  score: '团团转积分',
  score_log: '轮高分',
  opponent_score: '对手分',
}

export const scoresMap = {
  ...duplicatedMatchScoresMap,
  ...duplicatedSwitzerMatchScoresMap,
  ...singlePublicMatchScoresMap,
  ...spinMatchScoresMap,
  ...duplicatedSpinMatchScoresMap,
  ...normalScoresMap,
}

export const getScoresMap = (match_type) => {
  let map: Record<string, string> = normalScoresMap
  if (match_type === 'duplicated_switzer') {
    map = duplicatedSwitzerMatchScoresMap
  } else if (match_type === 'duplicated_team') {
    map = duplicatedTeamMatchScoresMap
  } else if (match_type === 'duplicated_spin') {
    map = duplicatedSpinMatchScoresMap
  } else if (match_type.includes('duplicated')) {
    map = duplicatedMatchScoresMap
  } else if (match_type === 'single_public') {
    map = singlePublicMatchScoresMap
  } else if (match_type === 'spin') {
    map = spinMatchScoresMap
  }
  return map
}

export const scoreToColumn = (i, match_type = '') => {
  const map = getScoresMap(match_type)

  return {
    title: map[i] || '积分',
    data: i,
  }
}

export const isSinglePlayerMatch = (match_type) => {
  return ['single', 'spin', 'single_public', 'duplicated_single', 'duplicated_spin'].includes(match_type)
}

export const normalMatchType = {
  team: '组队赛',
}

export const svipMatchMap = {
  single: '单人赛',
  circle: '循环赛',
  spin: '团团转',
}

export const ssvipMatchMap = {
  single_public: '单人赛(全国个人公开赛)',
  excess: '超分赛(过A继续打)',
  duplicated: '米切尔复式赛',
  duplicated_single: '单人复式赛',
  duplicated_team: '四人队式开闭室复式赛',
  duplicated_switzer: '瑞士偏差分复式赛',
  duplicated_spin: '偏差分团团转复式赛',
  league: '联赛',
}

export const matchTypeMap = {
  ...normalMatchType,
  ...svipMatchMap,
  ...ssvipMatchMap,
}

export const VipLevelMap = {
  10: 'NORMAL',
  20: 'VIP',
  30: 'SVIP',
  40: 'SSVIP',
}

export const initCardsSuit_SHCD = [
  'BJ',
  'KS',
  'QS',
  'JS',
  '10S',
  '9S',
  '8S',
  '7S',
  '6S',
  '5S',
  '4S',
  '3S',
  '2S',
  'AS',
  'KH',
  'QH',
  'JH',
  '10H',
  '9H',
  '8H',
  '7H',
  '6H',
  '5H',
  '4H',
  '3H',
  '2H',
  'AH',
  'KC',
  'QC',
  'JC',
  '10C',
  '9C',
  '8C',
  '7C',
  '6C',
  '5C',
  '4C',
  '3C',
  '2C',
  'AC',
  'KD',
  'QD',
  'JD',
  '10D',
  '9D',
  '8D',
  '7D',
  '6D',
  '5D',
  '4D',
  '3D',
  '2D',
  'AD',
  'LJ',
  'BJ',
  'KS',
  'QS',
  'JS',
  '10S',
  '9S',
  '8S',
  '7S',
  '6S',
  '5S',
  '4S',
  '3S',
  '2S',
  'AS',
  'KH',
  'QH',
  'JH',
  '10H',
  '9H',
  '8H',
  '7H',
  '6H',
  '5H',
  '4H',
  '3H',
  '2H',
  'AH',
  'KC',
  'QC',
  'JC',
  '10C',
  '9C',
  '8C',
  '7C',
  '6C',
  '5C',
  '4C',
  '3C',
  '2C',
  'AC',
  'KD',
  'QD',
  'JD',
  '10D',
  '9D',
  '8D',
  '7D',
  '6D',
  '5D',
  '4D',
  '3D',
  '2D',
  'AD',
  'LJ',
]

export const initCardsSuit_SCHD = [
  'BJ',
  'KS',
  'QS',
  'JS',
  '10S',
  '9S',
  '8S',
  '7S',
  '6S',
  '5S',
  '4S',
  '3S',
  '2S',
  'AS',
  'KC',
  'QC',
  'JC',
  '10C',
  '9C',
  '8C',
  '7C',
  '6C',
  '5C',
  '4C',
  '3C',
  '2C',
  'AC',
  'KH',
  'QH',
  'JH',
  '10H',
  '9H',
  '8H',
  '7H',
  '6H',
  '5H',
  '4H',
  '3H',
  '2H',
  'AH',
  'KD',
  'QD',
  'JD',
  '10D',
  '9D',
  '8D',
  '7D',
  '6D',
  '5D',
  '4D',
  '3D',
  '2D',
  'AD',
  'LJ',
  'BJ',
  'KS',
  'QS',
  'JS',
  '10S',
  '9S',
  '8S',
  '7S',
  '6S',
  '5S',
  '4S',
  '3S',
  '2S',
  'AS',
  'KC',
  'QC',
  'JC',
  '10C',
  '9C',
  '8C',
  '7C',
  '6C',
  '5C',
  '4C',
  '3C',
  '2C',
  'AC',
  'KH',
  'QH',
  'JH',
  '10H',
  '9H',
  '8H',
  '7H',
  '6H',
  '5H',
  '4H',
  '3H',
  '2H',
  'AH',
  'KD',
  'QD',
  'JD',
  '10D',
  '9D',
  '8D',
  '7D',
  '6D',
  '5D',
  '4D',
  '3D',
  '2D',
  'AD',
  'LJ',
]

export const formatOneIntegerCard = (cardNumber) => {
  const formated = CARD_EXAMPLE[cardNumber]
  if (!formated) {
    return {}
  }
  return { suit: formated.slice(-1), point: formated.slice(0, -1), pointNumber: rankToNumber[formated.slice(0, -1)], num: cardNumber }
}

export const formatIntegerCards = (cards: number[]) => (cards ?? []).map((c) => formatOneIntegerCard(c))

const direction = ['东', '南', '西', '北']

export const formatCards = (cards) => {
  const res = { 东: [], 南: [], 西: [], 北: [] }
  for (let i = 0; i < cards.length; i += 1) {
    if (cards[i].length > 0) {
      res[direction[3 - i]] = cards[i].map((c) => FormatCard(c))
    }
  }
  return res
}

export const statsKeyMap = {
  team1_rank12: '东西方一二游',
  team1_rank13: '东西方一三游',
  team1_rank14: '东西方一四游',
  team2_rank12: '南北方一二游',
  team2_rank13: '南北方一三游',
  team2_rank14: '南北方一四游',
}

export const clubMap = {
  tianhehui: '天和汇',
  yong: '雍冠',
  laoyangfang: '老洋房',
  wzw: '王中王',
}

export const dealMode = {
  suited: '同花色',
  bomb: '天王炸',
  random: '随机',
  fun: '不洗牌',
  fair: '均衡',
  diy: '自选牌',
}

export const numberToRankFunc = (rank, is_excess_match = false, match_type = '') => {
  if (rank === undefined || rank === null) {
    return null
  }
  if (parseInt(rank, 10) === -10) {
    return '弃'
  }
  if (match_type.startsWith('spin')) {
    return rank
  }
  if (match_type.startsWith('duplicated')) {
    return rank
  }
  if (parseInt(rank, 10) === 10) {
    return (
      <span>
        <span>1</span>
        <span className="ml-[-0.1125em]">0</span>
      </span>
    )
  }
  if (parseInt(rank, 10) < 15) {
    return <span>{numberToRank[rank]}</span>
  }
  if (is_excess_match) {
    return (
      <span className="text-yellow-600">
        {numberToRank[parseInt(rank, 10) - 13]}
        <span className="absolute -mt-1 ml-[-0.25em] text-lg font-black">+</span>
      </span>
    )
  }
  if (parseInt(rank, 10) > 15) {
    return <span>err</span>
  }
  return (
    <span className="text-yellow-600">
      A<span className="absolute -mt-1 ml-[-0.25em] text-lg font-black">+</span>
    </span>
  )
}
